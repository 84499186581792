import * as React from "react"
import { graphql } from "gatsby"
import { PageContainer } from "../basic/PageContainer"
import { theme } from "../theme.css"

interface IPageProps {
  data: {
    page: {
      id: string
      title: string
      slug: string
      description: string
      image: { id: string; url: string }
      html: string
    }
  }
}

export default function Page(props: IPageProps) {
  const { page } = props.data

  return (
    <PageContainer page={page}>
      <div
        style={{ color: theme.colors.long_text }}
        dangerouslySetInnerHTML={{
          __html: page.html,
        }}
      />
    </PageContainer>
  )
}

export const query = graphql`
  query PageContent($id: String!) {
    page(id: { eq: $id }) {
      id
      title
      slug
      description
      image {
        id
        url
      }
      html
    }
  }
`
